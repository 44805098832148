@use '../../styles/mixins' as mixins;

.features-module.animated {
    
    text-align: center;
    border: solid 1px  var(--wp--preset--color--bnpp-green);
    padding: var(--wp--preset--spacing--40);
    text-wrap: balance;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;

    img {
        max-width: 40px;
        display: block;
        margin: 0 auto var(--wp--preset--spacing--40);
    }

    h3 {
        font-size: var(--wp--preset--font-size--heading-xs);
        color: var(--wp--preset--color--bnpp-green);
    }


}

a.features-module.animated {
    
    position: relative;
    text-decoration: none;
    color: var(--wp--preset--color--bnpp-black);
    
    & {
        @include mixins.hoverKeyframes;
    }


    &::after {
        content: "";
        position: absolute;
        inset: 0;
        opacity: 0;
        border: solid 4px var(--wp--preset--color--bnpp-green);
        transition: 300ms;
    }

    &:hover {

        &::after {
            opacity: 1;
        }

        @include mixins.hoverAnimationsStart;
    }
    

}